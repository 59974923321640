import { useMemo } from 'react';

import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Img,
  Link,
  Text,
} from '@chakra-ui/react';

import { useAnalytics } from '@r-client/shared/data/analytics';
import { ArrowUpIcon, TriangleIcon } from '@r-client/shared/ui/chakra-ui-icons';

import { getDeals } from './contents';

export interface IDealsProps {
  region: 'us' | 'europe';
}

export const Deals = ({ region }: IDealsProps) => {
  const analytics = useAnalytics();

  const deals = useMemo(() => getDeals(region), [region]);

  return (
    <Box
      maxW={1112}
      mx={{ base: 4, xs: 8, lg: 'auto' }}
      mb={{ base: 10, xs: 24, lg: 40 }}
    >
      <Grid
        gap={{ base: '6', md: '9' }}
        gridTemplateColumns={{
          base: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
        }}
      >
        {deals.map((deal) => (
          <GridItem
            key={deal.title}
            borderRadius="3xl"
            background="grey.50"
            py="4"
            px={{ base: '4', md: '6' }}
            position="relative"
            display="flex"
            flexDirection="column"
          >
            <ArrowUpIcon
              color="black"
              boxSize="8"
              transform="rotate(45deg)"
              position="absolute"
              top="4"
              right="4"
            />
            <Link
              href={deal.link}
              target={deal.target}
              _hover={{ textDecoration: 'none' }}
              display="flex"
              flexDirection={{ md: 'column' }}
              mb="5"
              onClick={() => {
                analytics.track({
                  name: 'homepage_deals_links_clicked',
                  params: {
                    title: deal.title,
                    link: deal.link,
                    text: deal.cta,
                  },
                });
              }}
              variant="republic-default"
              size="republic-default"
            >
              <Flex
                my={{ md: '14' }}
                mr={{ base: '4', md: '0' }}
                justifyContent="center"
                alignItems="center"
                height={{ md: '166px' }}
                minW={{ base: '71px', md: 'unset' }}
                w={{ base: '71px', md: 'unset' }}
              >
                <Img display={{ base: 'none', md: 'block' }} {...deal.image} />
                <Img display={{ md: 'none' }} {...deal.mobileImage} />
              </Flex>
              <Box mr={{ base: '2', md: 'unset' }}>
                <Heading
                  as="p"
                  mb={{ base: '2', md: '4' }}
                  size={{ md: 'republic-h4' }}
                >
                  {deal.title}
                </Heading>
                <Text fontSize="14px" size={{ md: 'republic-body-lg' }}>
                  {deal.description}
                </Text>
              </Box>
            </Link>
            <Divider mt="auto" mb="4" color="black" opacity="0.1" />
            <Link
              target={deal.target}
              href={deal.link}
              display="flex"
              alignItems="center"
              onClick={() => {
                analytics.track({
                  name: 'homepage_deals_links_clicked',
                  params: {
                    title: deal.title,
                    link: deal.link,
                    text: deal.cta,
                  },
                });
              }}
              variant="republic-default"
              size="republic-default"
            >
              <Text size={{ md: 'republic-body-lg' }}>{deal.cta}</Text>
              <TriangleIcon color="brand.primary" boxSize="2" ml="1.5" />
            </Link>
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
};
