import {
  Box,
  Button,
  Flex,
  Link,
  SimpleGrid,
  Skeleton,
  useMediaQuery,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';

import {
  extractDealProps,
  OfferingCard,
} from '@r-client/republic/feature/offering-list';
import { useAnalytics } from '@r-client/shared/data/analytics';

import { useGetUsOfferingsQuery } from '../../graphql/get-us-offerings-query';
import {
  IGetUsOfferingsQuery,
  IGetUsOfferingsQueryVariables,
} from '../../graphql/types';

import styles from './us-offerings.module.css';

type IOfferingItem = IGetUsOfferingsQuery['offerings']['edges'][number]['node'];
const isOffering = (node: IOfferingItem | undefined) => {
  return node?.__typename === 'Offering';
};

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface IUsOfferingsProps {
  first?: number;
  scopeFilter?: IGetUsOfferingsQueryVariables['scopeFilter'];
  scopeBaseFilter?: IGetUsOfferingsQueryVariables['baseScopeFilters'];
  viewAllButtonHref?: string;
  viewAllButtonText?: string;
}

export const UsOfferings = observer(function UsOfferings({
  first = 3,
  scopeFilter,
  scopeBaseFilter,
  viewAllButtonHref,
  viewAllButtonText,
}: IUsOfferingsProps) {
  const offeringsQuery = useGetUsOfferingsQuery({
    variables: {
      first,
      scopeFilter: scopeFilter,
      baseScopeFilters: scopeBaseFilter,
    },
  });

  const [isTablet] = useMediaQuery(
    `(min-width: 600px) and (max-width: 1170px)`
  );

  const analytics = useAnalytics();

  const showViewMoreButton =
    offeringsQuery.data?.offerings.pageInfo.hasNextPage && !!viewAllButtonHref;

  return (
    <Box data-testid="us-offerings">
      {offeringsQuery.isLoading ? (
        <Flex
          flexWrap="wrap"
          justifyContent="space-between"
          maxW={{ base: 526, lg: '100%' }}
          mx="auto"
        >
          {Array.from({ length: first }, (_, i) => {
            return (
              <Skeleton
                isLoaded
                data-testid="skeleton-offering-card"
                key={`${i}_skeleton`}
                minWidth={isTablet ? '251px' : '347px'}
                width={isTablet ? '251px' : '347px'}
                height={isTablet ? '308px' : '503px'}
                bgColor="grey.600"
                borderRadius={isTablet ? '4px' : '12px'}
                opacity="8%"
                mb={{ base: 8, xs: 6 }}
              />
            );
          })}
        </Flex>
      ) : (
        <SimpleGrid
          columns={{ base: 1, sm: 3, md: 3 }}
          spacing={{ base: '6', lg: '35px' }}
          mt="6"
          mx="auto"
        >
          {offeringsQuery.data?.offerings.edges
            ?.slice(0, first)
            ?.map((offering) => {
              const onCardClick = () => {
                isOffering(offering.node) &&
                  analytics.track({
                    name: 'offering_clicked',
                    params: {
                      offering_id: +offering.node.id,
                      offering_slug: offering.node.slug,
                      spotlight: !!offering.node.spotlight,
                      category:
                        offering.node.verticalTag?.replace('v-', '') ?? null,
                      days_remaining:
                        offering.node.timeRemainingValueForDisplay ??
                        '' + offering.node.timeRemainingUnitForDisplay,
                      trending: offering.node.trending,
                      list_id: 'homepage',
                      tags: offering.node.tags?.map((tag) => tag.displayName),
                      platform: 'web',
                      source: 'republic',
                    },
                  });
              };
              return offering ? (
                <OfferingCard
                  className={styles.card}
                  key={offering.node.id}
                  type={isTablet ? 'small' : 'default'}
                  {...extractDealProps(offering.node)}
                  onClick={onCardClick}
                />
              ) : null;
            })}
          {showViewMoreButton && (
            <>
              <Box display={{ base: 'none', sm: 'block' }} />
              <Link
                href={viewAllButtonHref}
                textDecoration="solid"
                _hover={{ textDecoration: 'none' }}
                onClick={() =>
                  analytics.track({
                    name: 'click_view_all',
                  })
                }
                variant="republic-default"
                size="republic-default"
              >
                <Button
                  borderRadius={{ base: 'lg', xs: 'md' }}
                  size="lg"
                  bg="white"
                  px="10"
                  fontSize="lg"
                  w="100%"
                  variant="republic-default"
                >
                  {viewAllButtonText ?? 'View all'}
                </Button>
              </Link>
            </>
          )}
        </SimpleGrid>
      )}
    </Box>
  );
});
