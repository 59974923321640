import {
  Box,
  Button,
  Grid,
  GridItem,
  Link,
  SimpleGrid,
  Skeleton,
  useMediaQuery,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';

import { useAnalytics } from '@r-client/shared/data/analytics';

import { cardDataFromSeedrsRecord } from '../../utils/helper/europe-offering-card';
import { EuropeOfferingCard } from '../europe-offering-card/europe-offering-card';
import { useEuropeOfferingQuery } from './eu-offerings-query';

export interface IEuOfferingsProps {
  first: number;
  sort: 'most_recent_desc' | 'trending_desc';
  viewAllButtonHref?: string;
  viewAllButtonText?: string;
}

export const EuOfferings = observer(function EuOfferings({
  first,
  sort,
  viewAllButtonHref,
  viewAllButtonText,
}: IEuOfferingsProps) {
  const offeringsQuery = useEuropeOfferingQuery({
    page_size: first,
    sort,
  });

  const analytics = useAnalytics();

  const [isTablet] = useMediaQuery(
    `(min-width: 600px) and (max-width: 1170px)`
  );

  const showViewMoreButton = !!viewAllButtonHref;

  return (
    <Box data-testid="eu-offerings">
      {offeringsQuery?.state === 'pending' ? (
        <Grid
          mt="8"
          gap="9"
          gridTemplateColumns={{
            base: 'repeat(1, 1fr)',
            xs: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
          }}
        >
          {Array.from({ length: first }, (_, i) => {
            return (
              <Skeleton
                isLoaded
                data-testid="skeleton-offering-card"
                key={`${i}_skeleton`}
                minWidth={isTablet ? '251px' : '347px'}
                width={isTablet ? '251px' : '347px'}
                height={isTablet ? '308px' : '503px'}
                bgColor="grey.600"
                borderRadius={isTablet ? '4px' : '12px'}
                opacity="8%"
                mb={{ base: 8, xs: 6 }}
              />
            );
          })}
        </Grid>
      ) : (
        <SimpleGrid
          columns={{ base: 1, sm: 3, md: 3 }}
          spacing={{ base: '6', lg: '35px' }}
          mt="6"
          mx="auto"
        >
          {offeringsQuery?.value?.result
            ?.slice(0, first)
            ?.map((offering) => cardDataFromSeedrsRecord(offering))
            .map((offering) => {
              return (
                <GridItem key={offering.slug} p="0">
                  <EuropeOfferingCard cardData={offering} />
                </GridItem>
              );
            })}
          {showViewMoreButton && (
            <>
              <Box display={{ base: 'none', sm: 'block' }} />
              <Link
                href={viewAllButtonHref}
                textDecoration="solid"
                _hover={{ textDecoration: 'none' }}
                onClick={() =>
                  analytics.track({
                    name: 'click_view_all',
                  })
                }
                variant="republic-default"
                size="republic-default"
              >
                <Button
                  borderRadius={{ base: 'lg', xs: 'md' }}
                  size="lg"
                  bg="white"
                  px="10"
                  fontSize="lg"
                  w="100%"
                  variant="republic-default"
                >
                  {viewAllButtonText ?? 'View all'}
                </Button>
              </Link>
            </>
          )}
        </SimpleGrid>
      )}
    </Box>
  );
});
