/* eslint-disable max-lines */

import { useMemo } from 'react';

import {
  Box,
  Flex,
  Image,
  Link,
  Skeleton,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import TimeAgo from 'react-timeago';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ClientRender } from '@r-client/shared/util/components';
import { centsToDollars, formatDollars } from '@r-client/shared/util/core';

import { useTickerInvestmentsQuery } from '../../graphql/ticker-investments-query';
import { ITickerInvestmentsQuery } from '../../graphql/types';
import userDefault from '../../utils/images/user-default.svg';

import styles from './last-investments.module.scss';

interface ILastInvestmentsProps {
  tickerInvestments: ITickerInvestmentsQuery['tickerInvestments'];
  noBorder?: boolean;
  noMargin?: boolean;
}

const defaultSlidesInRow = 4;

export const LastInvestments = observer(function LastInvestments({
  tickerInvestments,
  noBorder,
  noMargin,
}: ILastInvestmentsProps) {
  const [isLargerThanLg] = useMediaQuery(`(min-width: 1170px)`, {
    ssr: true,
  });
  const [isLargerThanMd] = useMediaQuery(
    `(min-width: 769px) and (max-width: 1169px)`,
    {
      ssr: true,
    }
  );
  const [isLargerThanSm] = useMediaQuery(
    `(min-width: 376px) and (max-width: 768px)`,
    {
      ssr: true,
    }
  );
  const { data, isLoading } = useTickerInvestmentsQuery({
    variables: {
      first: 16,
    },
  });
  // @TODO: https://linear.app/republic/issue/FLY-961/change-investors-ticker-slider-from-slick-to-swiper
  // this is a very complex logic to fight with cloned slides issue. need to move to swiper
  const isInfinite = useMemo(() => {
    if (data?.tickerInvestments?.nodes.length) {
      return (
        data?.tickerInvestments?.nodes?.length >
        (isLargerThanLg ? 4 : isLargerThanMd ? 3 : isLargerThanSm ? 2 : 1)
      );
    }
  }, [
    data?.tickerInvestments?.nodes,
    isLargerThanLg,
    isLargerThanMd,
    isLargerThanSm,
  ]);

  return (
    <ClientRender>
      <Box
        maxW={1152}
        mx={{ base: 4, xs: 8, lg: 'auto' }}
        py={noBorder ? undefined : { base: 8, lg: 14 }}
        mb={noMargin ? undefined : { base: 9, xs: 16, lg: 36 }}
        maxH={{ lg: '162px' }}
        borderY={noBorder ? 'none' : '1px solid'}
        overflow={{ lg: 'hidden' }}
        borderColor={{ base: 'grey.100', lg: 'rgba(0, 0, 0, 0.2)' }}
      >
        <Swiper
          slidesPerView={1.4}
          spaceBetween={32}
          speed={5000}
          autoplay={{
            delay: 500,
            stopOnLastSlide: false,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
          loop={isInfinite}
          modules={[Autoplay]}
          breakpoints={{
            768: {
              slidesPerView: 2.6,
              spaceBetween: 32,
              slidesPerGroup: 1,
            },
            970: {
              slidesPerView: 3.4,
              spaceBetween: 32,
              slidesPerGroup: 3,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 32,
              slidesPerGroup: 4,
            },
          }}
        >
          {data?.tickerInvestments?.nodes?.map((investment) => {
            const amountInDollars = investment.amountPublic
              ? ` ${formatDollars(centsToDollars(investment.amountCents || 0))}`
              : '';

            return (
              <SwiperSlide key={investment.id} className={styles.slide}>
                <Flex
                  alignItems="start"
                  gap="2.5"
                  flexDirection="row"
                  flexWrap="nowrap"
                >
                  <Link
                    cursor={
                      investment.userVisibilityPublic ? 'pointer' : 'default'
                    }
                    display="contents"
                    href={
                      investment.userVisibilityPublic
                        ? `@${investment.userSlug}`
                        : undefined
                    }
                    variant="republic-default"
                    size="republic-default"
                  >
                    <Image
                      borderRadius="base"
                      boxSize={9}
                      src={investment.avatarUrl || userDefault}
                      srcSet={`${investment.avatarUrl2x} 2x`}
                      objectFit="contain"
                    />
                  </Link>
                  <Box>
                    <Text
                      color="grey.900"
                      fontSize="14px"
                      lineHeight="normal"
                      mb={0.5}
                      pr={8}
                    >
                      <Link
                        cursor={
                          investment.userVisibilityPublic
                            ? 'pointer'
                            : 'default'
                        }
                        href={
                          investment.userVisibilityPublic
                            ? `@${investment.userSlug}`
                            : undefined
                        }
                        variant="republic-default"
                        size="republic-default"
                      >
                        {investment.investorFirstName}{' '}
                        {investment.investorLastName?.charAt(0)}.{' '}
                      </Link>
                      invested{amountInDollars} in{' '}
                      <Link
                        href={`${investment.offeringSlug}`}
                        variant="republic-default"
                        size="republic-default"
                      >
                        {investment.companyName}
                      </Link>{' '}
                    </Text>
                    <Text
                      fontSize="13px"
                      lineHeight="16px"
                      color="grey.900"
                      opacity="0.4"
                    >
                      <TimeAgo
                        date={new Date(investment.createdAt || '')}
                        live={false}
                      />
                    </Text>
                  </Box>
                </Flex>
              </SwiperSlide>
            );
          })}
          {!tickerInvestments?.nodes?.length ||
            (isLoading &&
              Array.from({ length: defaultSlidesInRow }).map((_, i) => {
                return (
                  <SwiperSlide key={`skeleton_${i}`}>
                    <Flex
                      alignItems="start"
                      gap="2.5"
                      flexDirection="row"
                      flexWrap="nowrap"
                      data-testid="skeleton"
                    >
                      <Skeleton
                        borderRadius="base"
                        boxSize={9}
                        bg="grey.50"
                        animation="none"
                      />
                      <Box>
                        <Skeleton
                          borderRadius="xl"
                          w="162px"
                          h="16px"
                          bg="grey.50"
                          animation="none"
                          mb="6px"
                        />
                        <Skeleton
                          borderRadius="xl"
                          w="136px"
                          h="11px"
                          bg="grey.50"
                          animation="none"
                        />
                      </Box>
                    </Flex>
                  </SwiperSlide>
                );
              }))}
        </Swiper>
      </Box>
    </ClientRender>
  );
});
